import { ReactElement, useEffect } from 'react'
import { AuthLayout } from '@components/Layout'
import type { NextPageWithLayout } from './_app'
import { Flex } from '@chakra-ui/react'
import { useRouter } from 'next/router'

const Page: NextPageWithLayout = () => {
  const router = useRouter()

  useEffect(() => {
    router.push('/appointment')
  }, [])

  return <Flex minH="100vh"></Flex>
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>
}

export default Page
